import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import Layout from '../components/shared/layout';
import SEO from '../components/SEO/SEO';
import { wrapper, color, breakpoints, sizing, styleConsoleLog, border, gradient } from '../utils/style';
import ContactAll from '../components/Content/Contact/ContactAll';
import RecentPosts from '../components/Content/RecentPosts';
import Breadcrumbs from '../components/shared/Breadcrumbs';

// Imagrs
import telWhite from '../img/telWhite.svg';
import IcomoonIcon from '../components/shared/IcomoonIcon';

const Wrapper = styled('div')`
  ${wrapper};
  margin: 20px auto;

  .gatsby-image-wrapper {
    height: 140px;
  }
  .breadcrumbs {
    position: absolute;
    left: 20px;
    top: 200px;

    @media (max-width: ${breakpoints.tl}) {
      top: 125px;
      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
  }
  p {
    margin-bottom: 1rem;
    @media (max-width: ${breakpoints.md}) {
      padding: 0 15px 10px;
    }
  }

  div p {
    padding: 0;
  }
`;

const Bg = styled('div')`
  background-color: ${color.blockPurple};
  border-radius: ${border.borderRadius4};
`;
const RelatedWrapper = styled('div')`
  ${wrapper};
  margin-top: 10px;
  h3 {
    font-size: 1.6rem;
    margin-top: 0;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    display: inline-block;
    vertical-align: top;
    width: 49%;
    margin-bottom: 1rem;
  }
  li:nth-of-type(odd) {
    padding-right: 20px;
  }
  li:nth-of-type(even) {
    padding-left: 20px;
  }
  .recent-excerpt div {
    margin: 10px 0;
    font-size: ${sizing.small};
  }

  @media (max-width: ${breakpoints.md}) {
    padding: 0;
    h3 {
      font-size: 1.2rem;
    }
    ul {
      margin: 10px 0;
    }
    li {
      width: 100%;
      padding: 10px 0 !important;
    }

    & > div {
      padding-top: 0;
      margin-top: 60px;
    }
  }

  @media (max-width: ${breakpoints.xs}) {
    .recent-excerpt div,
    .recent-excerpt p {
      display: inline;
    }
    .recent-excerpt .button {
      padding: 0;
      background: transparent;
      display: block;
      text-align: left;
      line-height: 1.3;
      color: ${color.blue};
      font-weight: ${sizing.normalWeight};
      font-size: inherit;
      text-decoration: underline;
      &:active,
      &:focus,
      &:hover {
        color: ${color.lightGreen};
        background: none;
      }
    }
  }
`;

const ContentWrapper = styled('div')`
  width: ${(currPage) => (currPage === '/contact-us/' ? '794px' : '100%')};
  font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  position: relative;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${color.alabaster};
  @media (max-width: ${breakpoints.md}) {
    width: auto;
    font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.62;
    letter-spacing: normal;
    color: ${color.alabaster};
    padding: ${(props) => (props.currPage === '/contact-us/' ? '40px 15px 0' : '0px 15px')};

    & p {
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    & p {
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    @media (orientation: portrait) {
      margin-top: ${(props) => (props.currPage === '/contact-us/' ? '12vh' : '0')};
      @media (min-height: ${breakpoints.md}) {
        margin-top: ${(props) => (props.currPage === '/contact-us/' ? '7vh' : '0')};
      }
    }
    @media (orientation: landscape) {
      margin-top: ${(props) => (props.currPage === '/contact-us/' ? '20vh' : '0')};
    }
  }
  .lnwasia-text {
    font-size: 24px;
    font-weight: bold;
    
    &.hotline {
      color: ${color.darkPink};
    }

    @media (max-width: ${breakpoints.md}) {
      font-size: 13px;
    }
  }

  .sbobetAgentFirstLeftBlock,
  .sbobetAgentLeftSecondBlock,
  .sbobetAgentRightBlock {
    width: 49%;

    @media (max-width: ${breakpoints.tl}) {
      width: 95%;
    }
  }

  .sbobetAgentRightBlock {
    width: 49%;
    position: absolute;
    top: 32%;
    right: 0;

    @media (max-width: ${breakpoints.tl}) {
      position: initial;
      width: 95%;
    }
  }

  .second-text {
    font-size: 24px;
    font-weight: bold;
    color: ${color.lightGreen};

    @media (max-width: ${breakpoints.md}) {
      font-size: 13px;
    }
  }
}
`;

const PageWrapper = styled('div')`
  display: inline-flex;

  @media (max-width: ${breakpoints.md}) {
    &.contact-us {
      display: flex;
      flex-direction: column-reverse;

      & .contact-us-content {
        margin-top: 20px;
        padding: 0;
      }
    }
  }
`;

const ButtonWrapper = styled('div')`
  display: block;
  margin-left: 20px;

  @media (max-width: ${breakpoints.md}) {
    width: 100%;
    margin-left: 0;
    margin-top: 0;
    padding-top: 20px;
    z-index: 1;
    font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
      width: 48%;
      margin: 0;
    }
  }
`;

const ContactNum = styled('div')`
  position: relative;
  width: 386px;
  height: 100px;
  border-radius: ${border.borderRadius14};
  background: ${color.darkPink};
  background: ${gradient.roseBittersweet};
  font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  padding: 12px 20px;
  user-select: none;
  cursor: default;
  :hover {
    opacity: 0.8;
  }
  .number-text {
    font-size: 30px;
    font-weight: bold;

    @media (max-width: ${breakpoints.md}) {
      width: 130px;
      height: 55px;
      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.58;
      letter-spacing: normal;
      font-size: 16px;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    width: calc(50% - 25px);
    height: 55px;
    padding: 10px 0 0 10px;
    font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: normal;
    margin: 20px 16px 0 20px;

    @media (min-height: ${breakpoints.md}) and (orientation: portrait) {
      margin: 10px 16px 0 15px;
    }

    @media (max-width: ${breakpoints.xxs}) and (orientation: portrait) {
      margin-top: 10px;
    }

    @media (max-width: ${breakpoints.sm}) and (orientation: landscape) {
      margin-top: 0;
    }
  }

  img {
    position: absolute;
    right: 22px;
    top: 24px;
    @media (max-width: ${breakpoints.md}) {
      width: 23px;
      right: 5px;
      top: 15px;
    }
  }
`;

const LNWASIAButton = styled('div')`
  position: relative;
  padding: 20px;
  margin-top: 30px;
  width: 386px;
  height: 100px;
  border-radius: ${border.borderRadius14};
  user-select: none;
  cursor: default;
  font-size: 20px;
  background: ${color.bunting};
  background: ${gradient.purpleBunting};
  font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
  line-height: 1.13;
  color: ${color.lineGreen};

  :hover {
    opacity: 0.8;
  }
  .lnwasia-text {
    font-size: 30px;
    font-family: 'Kanit', sans-serif;
    font-weight: bold;

    @media (max-width: ${breakpoints.md}) {
      width: 140px;
      height: 55px;
      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-size: 16px;
      line-height: 1.58;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    width: calc(50% - 25px);
    height: 55px;
    padding: 10px 0 0 10px;
    font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
    font-size: 12px;
    line-height: 1.58;
    margin: 20px 0 0 0;
    @media (min-height: ${breakpoints.md}) and (orientation: portrait) {
      margin: 10px 16px 0 0;
    }

    @media (max-width: ${breakpoints.xxs}) and (orientation: portrait) {
      margin-top: 10px;
    }

    @media (max-width: ${breakpoints.sm}) and (orientation: landscape) {
      margin-top: 0;
    }
  }

  img,
  .iconImage {
    position: absolute;
    top: 10px;
    right: 13px;

    @media (max-width: ${breakpoints.md}) {
      width: 32px;
      height: 32px;
      right: 5px;
      top: 13px;
    }
  }
`;

const Date = styled('p')`
  color: ${color.yellow};
  margin: 0.5rem 0 1.5rem;

  @media (max-width: ${breakpoints.md}) {
    padding-bottom: 0 !important;
    margin-top: 10px;
    margin-bottom: 10px !important;
  }

  @media (max-width: ${breakpoints.md}) {
    padding-bottom: 0 !important;
    margin-top: 10px;
    margin-bottom: 10px !important;
  }
`;

const Header = styled('div')`
  font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
  font-size: 26px;
  font-weight: bold;
  color: ${color.lightningYellow};

  @media (max-width: ${breakpoints.md}) {
    padding: 0 15px 0;
    margin-top: 20px;
  }
`;

const FeaturedWrapper = styled('div')`
  user-select: none;
  position: relative;
  .breadcrumbs {
    position: absolute;
    top: 20px;
    left: 30px;
    z-index: 1;
  }
  &.h-auto {
    min-height: auto;
    .breadcrumbs {
      position: relative;
      top: 0;
      left: 0;
    }
  }

  @media (max-width: ${breakpoints.tl}) {
    & .breadcrumbs {
      left: 16px;
      top: 16px;
    }
  }
`;

const HeaderImgText = styled('h1')`
  font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
  font-size: 44px;
  font-weight: bold;
  color: ${color.lightningYellow};

  @media (max-width: ${breakpoints.md}) {
    font-size: 24px;
    top: 50px;
    left: 16px;
  }
`;

const renderRelatedPosts = (contactUsPage = false, relatedIds = null) => {
  if (!contactUsPage) {
    if (relatedIds.length > 0) {
      return (
        <Bg>
          <RelatedWrapper>
            <RecentPosts />
          </RelatedWrapper>
        </Bg>
      );
    }
  }
};

// REFACTOR
// Spread mb more
const PageTemplate = ({ data, pageContext, img, imgName }) => {
  const currentPage = data.wordpressPage;
  const featuredMedia = currentPage.featured_media;
  const metaTitle =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_title
      ? currentPage.yoast_meta.yoast_wpseo_title
      : `${currentPage.title}- ${process.env.SITE_NAME_SHORT}`;
  const metaDesc =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_metadesc
      ? currentPage.yoast_meta.yoast_wpseo_metadesc
      : '';
  const relatedIds = pageContext.related;
  const contactPageWPID = process.env.CONTACT_PAGE_WP_ID;
  const { breadcrumbs } = pageContext;
  const socialImage = currentPage.featured_media?.localFile?.publicURL;

  const [currPage, setCurrPage] = useState('');

  const pathName = (url) => {
    setCurrPage(url);
    const paths = {
      '/contact-us/': () => {
        document.getElementById('hotline').classList.add('lnwasia-text', 'hotline');
        document.getElementById('lineId').classList.add('second-text');
      },
      '/sbobet-agent/': () => {
        const firstBlock = 'sbobetAgentFirstLeftBlock';
        const secondBlock = 'sbobetAgentLeftSecondBlock';
        const rightBlock = 'sbobetAgentRightBlock';
        document.getElementById(firstBlock).classList.add(firstBlock);
        document.getElementById(secondBlock).classList.add(secondBlock);
        document.getElementById(rightBlock).classList.add(rightBlock);
      },
      rest: () => {
        styleConsoleLog('Page (url path) not implemented in page.js');
      },
    };
    return paths[url] ? paths[url]() : paths.rest();
  };

  useEffect(() => {
    pathName(window.location.pathname);
  }, []);

  const sbobetAgentPage = currPage === '/sbobet-agent/';
  const contactUsPage = currPage === '/contact-us/';
  const freespinTermsPage = currPage === '/freespin-terms-and-conditions/';
  const predictionPage = currPage === '/world-cup-winner-prediction-prizes/';

  const lnwasiaHandleClick = () => window.open('https://line.me/R/ti/p/%40lnwasia');
  const phoneUsHandleClick = () => window.open('tel:02-508-8798');

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={currentPage.path}
        breadcrumbs={breadcrumbs}
        socialImage={socialImage}
      />
      {featuredMedia && (
        <FeaturedWrapper className={`${!featuredMedia ? 'h-auto' : ''}`}>
          <Breadcrumbs className="breadcrumbs" breadcrumbs={breadcrumbs} page_title={currentPage.title} />
          <Img
            fluid={featuredMedia?.localFile?.childImageSharp?.fluid}
            alt={currentPage.featured_media.alt_text ? currentPage.featured_media.alt_text : currentPage.title}
            style={{
              minHeight: '140px',
              borderRadius: '4px',
            }}
          />
        </FeaturedWrapper>
      )}

      <Wrapper>
        <HeaderImgText>
          {freespinTermsPage || contactUsPage
            ? 'ติดต่อเรา'
            : predictionPage
            ? 'กิจกรรมทายผลบอล World Cup 2022 ลุ้นรับเครดิตฟรีมูลค่ารวมกว่า 100,000 บาท'
            : 'หวยออนไลน์'}
        </HeaderImgText>
        {sbobetAgentPage && <Header>ทางเข้า SBOBET</Header>}
        {sbobetAgentPage && <Date>{currentPage.date}</Date>}
        {currentPage.wordpress_id === +contactPageWPID && <ContactAll />}
        <PageWrapper className={`${contactUsPage ? 'contact-us' : ''}`}>
          <ContentWrapper
            className={`${contactUsPage ? 'contact-us-content' : ''}`}
            currPage={currPage}
            dangerouslySetInnerHTML={{ __html: currentPage.content }}
          />
          {contactUsPage && (
            <ButtonWrapper>
              <ContactNum onClick={phoneUsHandleClick}>
                <div>เบอร์ Hotline:</div>
                <div className="number-text">02-508-8798</div>
                <img className="iconImage" src={telWhite} alt="red phone" />
              </ContactNum>
              <LNWASIAButton onClick={lnwasiaHandleClick}>
                <div>LineID:</div>
                <div className="lnwasia-text">{process.env.LINE_NAME}</div>
                <IcomoonIcon className="iconImage" icon="line-id" size={78} />
              </LNWASIAButton>
            </ButtonWrapper>
          )}
        </PageWrapper>
      </Wrapper>
      {!sbobetAgentPage && renderRelatedPosts(contactUsPage, relatedIds)}
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query ($id: String!, $related: [Int]) {
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      content
      date(formatString: "MMMM DD, YYYY")
      path
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        page_template
      }
      featured_media {
        alt_text
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1980) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allWordpressPost(filter: { wordpress_id: { in: $related } }, limit: 4) {
      edges {
        node {
          slug
          title
          excerpt
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 430) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    placeholder: file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        fluid(maxWidth: 430) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
